import React, { useEffect, useState } from 'react'
import './DashboardBox.css';
import { useSelector } from 'react-redux';
import { getSchemeAccountBalances } from '../../helper/Api';
import {Col, Container, Row} from 'react-bootstrap'
import {BiSolidEdit} from 'react-icons/bi';
import {AiOutlineEye} from 'react-icons/ai';

const InterestFromBankBox = ({handleEditAccount, reloadList}) => {
  const {authData}=useSelector((state)=>state.authData);
  const [accountBalances, setAccountBalances] = useState([]);

  const get_balance_interest=async()=>{
      const accountData = await getSchemeAccountBalances();
      if(accountData.data.status) {
        setAccountBalances(accountData.data.result);
      }
  }

  useEffect(() => {
    get_balance_interest();
  },[reloadList])

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Account Name</th>
                  <th>Account No</th>
                  <th>Account Balance</th>
                  <th>Balance As On</th>
                  <th>Accured Interest</th>
                  <th>Interest Date</th>
                  <th>Quarter</th>
                  <th>Action (L2)</th>
                  <th>Action (L3)</th>
                </tr>
              </thead>
              <tbody>
                {
                  accountBalances.map((e, i) => { 
                    return <tr key={i}>
                      <td>
                        {
                          authData.user.user_type === 'l1' ? 
                          e.l2_approval == 0 || e.l3_approval == 0 ? 
                          <button type="button" className="btn btn-primary" onClick={() => handleEditAccount(e)}><BiSolidEdit/></button> 
                          : <button type="button" className="btn btn-primary" onClick={() => handleEditAccount(e)}><AiOutlineEye/></button>
                          : ''
                        }
                        {
                          authData.user.user_type !== 'l1' && e.l2_approval == 1 && e.l3_approval == 1 ? 
                          <button type="button" className="btn btn-primary" onClick={() => handleEditAccount(e)}><AiOutlineEye/></button> 
                          : ''
                        }
                      </td>
                      <td>{e.account_name}</td>
                      <td>{e.account_no}</td>
                      <td>{e.account_balance}</td>
                      <td>{e.balance_date}</td>
                      <td>{e.interest}</td>
                      <td>{e.interest_date}</td>
                      <td>{e.quarter}</td>
                      <td>{e.l2_approval == null ? 
                      authData.user.user_type == 'l2' ? <button type="button" className='btn btn-secondary' onClick={() => handleEditAccount(e)}>Pending</button> : 'Pending'
                      : e.l2_approval == 1 ? 'Approved' : 'Rejected'}</td>
                      <td>{e.l3_approval == null ? 
                      authData.user.user_type == 'l3' ? <button type="button" className='btn btn-secondary' onClick={() => handleEditAccount(e)}>Pending</button> : 'Pending'
                      : e.l3_approval == 1 ? 'Approved' : 'Rejected'}</td>
                    </tr>
                    })
                  }
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default InterestFromBankBox
