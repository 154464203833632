import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    addActivity,
    editActivity,
    getActivity
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
const AddActivity = () => {
    const { id } = useParams();
    const { authData } = useSelector((state) => state.authData);
    const [formData, setFormData] = useState({
        status: "1",
        activity: '',
        created_by: authData.user.id,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleToggle = () => {
        setFormData({
            ...formData,
            status: formData.status === "0" ? "1" : "0",
        });
    }
    const handleFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    }
    const previewHandler = (e) => {
        e.preventDefault();
    };
    const validateForm = (data) => {
        if (!formData.activity) {
            return false;
        }
        return true;
    };
    const fetchData = async (id) => {
        const response = await getActivity(id);
        if (response.data.status) {
            setFormData(response.data.result[0])
        }
    }
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id]);

    const handleSubmit = async (e) => {
        if (!validateForm()) {
            alert('Activity is required.');
            return;
        }
        try {
            if (id) {
                const response = await editActivity({ activity: formData.activity, status: formData.status }, id);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/department/activity');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            else {
                const response = await addActivity({ activity: formData.activity });
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/department/activity');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                {/* <h4> Add Activity</h4> */}
                                <h4>{id ? 'Edit Activity' : 'Add Activity'}</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input className="form-control"
                                                            name='activity'
                                                            value={formData.activity}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter activity" />
                                                    </div>
                                                </div>
                                            </div>
                                            {id && (
                                                <div className="col-sm-12 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                                            Status
                                                            <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id="active"
                                                                    name="status"
                                                                    value={formData.status} onChange={handleToggle}
                                                                    checked={formData.status === "1"}
                                                                />
                                                                <label className="form-check-label" htmlFor="active">
                                                                    Active
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id="inactive"
                                                                    name="status"
                                                                    value={formData.status}
                                                                    onChange={handleToggle}
                                                                    checked={formData.status === "0"}
                                                                />
                                                                <label className="form-check-label" htmlFor="inactive">
                                                                    Inactive
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-sm-12 p-2 text-center">
                                                <Button variant="primary" onClick={handleSubmit}> SUBMIT </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )

};
export default AddActivity;