import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    addActivity,
    editActivity,
    getActivity, updateSchemeBankAccountApproval, get_departments, get_schemes, getSchemeAccountBalances,AddSchemeBankAccount
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'react-bootstrap'
import SchemeAccountPreview from '../Model/schemeAccountPreview';
const AddSchemeAccount = () => {
    const { id, viewId } = useParams();
    const isReadOnly = viewId;
    const { authData } = useSelector((state) => state.authData);
    const [department, setDepartment] = useState([]);
    const [scheme, setScheme] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [formData, setFormData] = useState({
        "department_id": "",
        "scheme_id": "",
        "account_id": "",
        "l2_approval": "",
        "l3_approval": "",
        "l1_remark": "",
        "l2_remark": "",
        "l3_remark": "",
        'added_by': authData.user.id
    })
    const navigate = useNavigate();
    const previewHandler = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };
    const fetchData = async (id) => {
        const response = await getActivity(id);
        if (response.data.status) {
            setFormData(response.data.result[0])
        }
    }
    useEffect(() => {
        fetchDepartments();
        fetchScheme();
        fetch_accounts();
        if (id || viewId) {
            fetchData(id || viewId);
        }
    }, [id || viewId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                const response = await editActivity({ activity: formData.activity, status: formData.status }, id);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/schemeAccountList');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            else {
                const response = await AddSchemeBankAccount(formData);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/schemeAccountList');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if(name === 'department_id') {
            await fetch_accounts(value);
        }
    }
    const onApprove = async (status) => {
        formData.l2_approval = authData.user.user_type == 'l2' ? status : formData.l2_approval;
        formData.l3_approval = authData.user.user_type == 'l3' ? status : formData.l3_approval;
        let approvalData = {
            l2_approval: formData.l2_approval,
            l3_approval: formData.l3_approval,
            l2_remark: formData.l2_remark,
            l3_remark: formData.l3_remark
        }
        const { data } = await updateSchemeBankAccountApproval(approvalData, formData.id);
    }
    const fetchDepartments = async () => {
        try {
            const departments = await get_departments({ department_level: '' });
            if (departments.data.status) {
                setDepartment(departments.data.result);
            }
        } catch (error) {
            console.log("departments");
        }
    }
    const fetchScheme = async () => {
        const schemes_list = await get_schemes();
        if (schemes_list.data.status) {
            setScheme(schemes_list.data.schemes);
        }
    }
    const fetch_accounts = async (value) => {
        const accounts = await getSchemeAccountBalances({department_id: value});
        if (accounts.data.status) {
            setAccountList(accounts.data.result);
            console.log("accounts", accountList);
        }
    }
    return (
        <div>
            <SchemeAccountPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData} handleSubmit={handleSubmit} department={department} scheme={scheme} accounts={accountList}></SchemeAccountPreview>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>{viewId ? 'View Scheme Account' : (id ? 'Edit Scheme Account' : 'Add Scheme Account')}</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <Container>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                                                    <select
                                                        className="form-control "
                                                        id="department_id"
                                                        name='department_id'
                                                        required
                                                        value={formData.department_id}
                                                        onChange={handleChange} readOnly={isReadOnly}>
                                                        <option value="">Select Department Type</option>
                                                        {
                                                            department.map((dept) => {
                                                                return (
                                                                    <option value={dept.id}>{dept.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Scheme<span className='text-danger'>*</span></Form.Label>
                                                    <select
                                                        className="form-control "
                                                        id="scheme_id"
                                                        name='scheme_id'
                                                        required
                                                        value={formData.scheme_id}
                                                        onChange={handleChange} readOnly={isReadOnly}>
                                                        <option value="">Select Scheme</option>
                                                        {
                                                            scheme.map((e) => {
                                                                return (
                                                                    <option value={e.id}>{e.code}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Bank <span className='text-danger'>*</span></Form.Label>
                                                    <select
                                                        className="form-control "
                                                        id="account_id"
                                                        name='account_id'
                                                        required
                                                        value={formData.account_id}
                                                        onChange={handleChange} readOnly={isReadOnly}>
                                                        <option value="">Select Bank</option>
                                                        {
                                                            accountList.map((e) => {
                                                                return (
                                                                    <option value={e.id}>{e.account_name} [A/c No: {e.account_no}]</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            authData.user.user_type == 'l1' ?
                                                <>
                                                    <label>L1 Remark</label>
                                                    <textarea className='form-control' onChange={handleChange} name="l1_remark" readOnly={isReadOnly}></textarea>
                                                </>
                                                : ''
                                        }
                                        <br></br>
                                        {
                                            (formData.l2_approval != 1 || formData.l3_approval != 1) && authData.user.user_type == 'l3' && !viewId ?
                                                <Button variant="primary" type="submit">
                                                    {formData?.id ? 'Update Scheme Account' : 'Add Scheme Account'}
                                                </Button>
                                                : ''
                                        }
                                        {
                                            authData.user.user_type == 'l2' && formData.l2_approval == null ?
                                                <>
                                                    <label>L2 Remark</label>
                                                    <textarea className='form-control' onChange={handleChange} name="l2_remark" readOnly={isReadOnly}></textarea>
                                                    <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                    <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                                </>
                                                : ''
                                        }
                                        {
                                            authData.user.user_type == 'l3' && formData.l3_approval == null ?
                                                <>
                                                    <label>L2 Remark</label>
                                                    <textarea className='form-control' onChange={handleChange} name="l3_remark" readOnly={isReadOnly}></textarea>
                                                    <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                    <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                                </>
                                                : ''
                                        }
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
};
export default AddSchemeAccount;