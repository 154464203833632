import React, { useState } from 'react'
import Tools from "../../../components/Tools/Tools"
import ManageDisbursmentHeader from '../../../components/Disbursment/ManageDisbursmentHeader'
import SubactivityList from '../../../components/Multi/Department-Subcategory/SubactivityList';

const SubactivityListPage = () => {
    const [searchStr, setToSearchStr] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setToSearchStr(searchStr);
    };
    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader type="Subactivity" onDateChange={handleDateChange} />
            <div className='scheme p-2'>
                <SubactivityList searchStr={searchStr}/>
            </div>
        </div>
    )
}
export default SubactivityListPage
