import React, { useState } from 'react'
import Tools from "../../../components/Tools/Tools"
import { useSelector } from 'react-redux';
import ManageDisbursmentHeader from '../../../components/Disbursment/ManageDisbursmentHeader'
import ChildAccountList from '../../../components/Multi/ChildAccount/AccountList';

const ChildAccountListPage = () => {
    const { authData } = useSelector((state) => state.authData);
    const [searchStr, setToSearchStr] = useState('');
    const [ti, setToTier] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr, fy_id, tier) => {
        setToSearchStr(searchStr);
        setToTier(tier)
    };
    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader type="Child Bank Accounts" onDateChange={handleDateChange} />
            <div className='scheme p-2'>
                <ChildAccountList searchStr={searchStr} department_level={ti}/>
            </div>
        </div>
    )
}
export default ChildAccountListPage
