import {useEffect, useState} from 'react';
// import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {getPPAList, downloadPPA} from '../../helper/Api';
import {useLocation, useParams} from 'react-router-dom';



export default function PPAList() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const batch_id = searchParams.get('batch_id');
    // const {authData} = useSelector((state) => state.authData);
    // const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [data, setData] = useState([]);

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        // setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const fetchPPAs = async () => {
        try {
            const resp = await getPPAList({
                batch_id: batch_id,
                from_date: fromDate,
                to_date: toDate
            });
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }

    useEffect(() => {
        fetchPPAs();
    }, []);

    const downloadPPAFun = async (url, isDownloaded, ppa_id) => {
        try {
            let formData = {
                ppa_id: ppa_id
            }
            const res = await downloadPPA(formData);
            if (res.data.status) {
                const link = document.createElement('a');
                link.href = url;
                link.download = `ppa_${ppa_id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                if(!isDownloaded) {
                    fetchPPAs();
                }
            }
        }catch(e) {
            console.log('Error downloading PPA', e);
        }
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'MOP',
            selector: (row) => row.payment_mode,
            wrap: true,
            width: "100px"
        },
        {
            name: 'PPA No.',
            selector: (row) => row.ppa_no + '-' + row.id,
            wrap: true,
            width: "150px"
        },
        {
            name: 'No. of Records',
            selector: (row) => row.invoice_count,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Amount',
            selector: (row) => row.amount,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Account No',
            selector: (row) => row.account_no,
            wrap: true,
            width: "300px"
        },
        {
            name: 'Approval Date/Time',
            selector: (row) => row.approval_time,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Action',
            selector: (row) => {
                if(row.is_downloaded == 0) {
                    return <button type="button" className='btn btn-danger' onClick={ () => downloadPPAFun(row.ppa_url, true, row.id) }>Download</button>
                } else{
                    return <button type="button" className='btn btn-success' onClick={ () => downloadPPAFun(row.ppa_url, false, row.id) }>Download</button>
                }
            },
            wrap: true,
            width: "150px"
        }
    ];

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}