import React from 'react';
import Tools from "../../../components/Tools/Tools"
import AddChildAccount from '../../../components/Multi/ChildAccount/AddChildAccount';

const AddAccountPage = () => {
  return (
    <div>
      <Tools />
      <AddChildAccount />
    </div>
  )
}
export default AddAccountPage
