import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router-dom";
import { getSubActivity } from '../../../helper/Api';
import { BiSolidEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import CustomTable from '../../CustomTable/CustomTable';
import { AiOutlineEye, AiFillDelete } from 'react-icons/ai';
const SubactivityList = (filterDate) => {
    const { authData } = useSelector((state) => state.authData);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let { state } = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    // const columns = [
    //     {
    //         name: 'id',
    //         selector: (row) => row.id,
    //         sortable: true,
    //         wrap: true,
    //         // width: "150px"
    //     },
    //     {
    //         name: 'Activity',
    //         selector: (row) => row.activity,
    //         sortable: true,
    //         // width: "150px",
    //         right: false
    //     },
    //     {
    //         name: 'Subactivity',
    //         selector: (row) => row.subactivity,
    //         sortable: true,
    //         // width: "150px",
    //         right: false
    //     },
    //     {
    //         name: 'Status',
    //         selector: (row) => row.status === "1" ? "Active" : "Inactive",
    //         sortable: true,
    //         // width: "150px",
    //         right: false
    //     },
    //     {
    //         name: 'Edit / Update',
    //         width: "150px",
    //         selector: (row) => {
    //             return <>
    //                 {
    //                     (authData.user.user_type === 'l1') ? <Link to={`/department/edit-subactivity/${row.id}}`}><BiSolidEdit /></Link> : ''
    //                 }
    //             </>
    //         },
    //         center: true,
    //     },
    // ];
    const columns = [
        { Header: 'ID', accessor: 'id', style: { width: '50px' } },
        { Header: 'Activity', accessor: 'activity', style: { width: '150px' } },
        { Header: 'Subactivity', accessor: 'subactivity', style: { width: '300px' } },
        {
            Header: 'Status', accessor: 'status',
            Cell: ({ value }) => (value === "0" ? "Inactive" : "Active"),
            style: { width: '100px' }
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            style: { width: '150px' },
            Cell: ({ row }) => (
                <td className="actions-cell"> {/* Add class for actions cell */}
                    <Link to={`/department/edit-subactivity/${row.id}`}>
                        <BiSolidEdit style={{ cursor: 'pointer', fontSize: '18px' }} />
                    </Link>
                    {/* <Link to={`/view-allocate-budget/${row.id}`}>
                        <AiOutlineEye style={{ cursor: 'pointer', fontSize: '18px' }} />
                    </Link> */}
                    {/* <AiFillDelete
                        style={{ cursor: 'pointer', color: 'red', fontSize: '18px' }}
                        onClick={() => alert(`Deleting ${row.id}`)}
                    /> */}
                </td>
            )
        },
    ];
    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
    };
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity({
                searchStr: filterDate.searchStr
            });
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    useEffect(() => {
        fetchSubActivity();
    }, [filterDate.searchStr])
    const handlePageChange = (newPage, limit) => {
        console.log(`Navigated to page ${newPage + 1}`, limit);
    };
    return (
        <div>
            {/* <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
            <CustomTable data={data} columns={columns} initialItemsPerPageOptions={[5, 10, 20, 50]} onPageChange={handlePageChange} />
        </div>
    )
}

export default SubactivityList
