import React, { useState } from 'react'
import Tools from "../../../components/Tools/Tools"
import { useSelector } from 'react-redux';
import ManageDisbursmentHeader from '../../../components/Disbursment/ManageDisbursmentHeader'
import SchemeAccountList from '../../../components/Multi/ChildSchemeAccount/SchemeAccountList';

const SchemeAccountListPage = () => {
    const { authData } = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('approval_date');
    const [searchStr, setToSearchStr] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
    };
    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader type="Child Scheme Accounts" />
            <div className='scheme p-2'>
                <SchemeAccountList />
            </div>
        </div>
    )
}
export default SchemeAccountListPage
