import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { delete_budget_by_id, get_budget_list } from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import { AddCommasToAmount, CustomPagination, show_l1_action_btn } from '../../../helper/Utils';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';

const BudgetPaymentList = (listType) => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);

    const fetchBudgets = async () => {
        try {
            const post_data = new FormData();
            post_data.append('from_date', listType?.fromDate || '');
            post_data.append('to_date', listType?.toDate || '');
            post_data.append('search_str', listType?.searchStr || '');

            const { data } = await get_budget_list(post_data, '');
            setData([])

            if (data.budgets) {
                if (listType.listType === 'list') {
                    // data.budgets.forEach((item, index) => {
                    //     item.subhead =  [
                    //         {
                    //           "id": "107",
                    //           "scheme_id": "68",
                    //           "name": "11 subhead 1",
                    //           "code": "11",
                    //           "financial_year": "2024-2025",
                    //           "budget": 100,
                    //           "provisional_budget": 1000,
                    //           "budget_date": "2024-04-01",
                    //           "added_from": "1",
                    //           "added_on": "2024-04-10 22:25:37",
                    //           "added_by": "12",
                    //           "budget_order_no": null,
                    //           "budget_order_date": null,
                    //           "fy_id": "2",
                    //           "balance": 100,
                    //           "payable_expanses": 0,
                    //           "utilised_budget": 0,
                    //           "pending_budget": 0,
                    //           "total_payment": 0
                    //         }
                    //       ];
                    // });
                    setData(data.budgets)
                } else if (listType.listType === 'actionList' && authData.user.user_type == 'l2') {
                    const list = data.budgets.filter(el => el.l2_status == 0);
                    setData(list)
                } else if (listType.listType === 'actionList' && authData.user.user_type == 'l3') {
                    const list = data.budgets.filter(el => el.l3_status == 0);
                    setData(list)
                }

            }
            // setData(data.budgets);
        } catch (error) {
            setData([]);
        }
    }

    useEffect(() => {
        fetchBudgets();
    }, [])

    useEffect(() => {
        fetchBudgets();
    }, [listType.fromDate, listType.toDate, listType.searchStr])

    useEffect(() => {
        fetchBudgets();
    }, [listType.listType])


    // const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const columns = [
        {
            name: 'Scheme code',
            selector: (row) => row.scheme_code,
            wrap: true,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Scheme Name',
            selector: (row) => <b>{row.scheme_name}</b>,
            wrap: true,
            // sortable: true,
            width: "300px",
        },
        {
            name: 'Subhead Code',
            selector: (row) => <b>{row.subhead_code}</b>,
            wrap: true,
            // sortable: true,
            width: "150px",
            center: true

        },
        {
            name: 'Financial Year',
            selector: (row) => {
                return (<b>{row.start_year + "-" + row.end_year}</b>);
            },
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Budget Release Date',
            selector: (row) => row.budget_date,
            sortable: true,
            width: "150px",
            wrap: true

        },
        {
            name: 'Provisi. Budget',
            selector: (row) => <b>{AddCommasToAmount(row.subhead_provisional_budget)}</b>,
            width: "200px",
            wrap: true,
            center: true,
            sortable: true,
            sortFunction: (a, b) => {
                a = a.provisional_budget;
                b = b.provisional_budget;
                const valueA = typeof a === 'string' ? parseFloat(a.replace(/,/g, '')) : a;
                const valueB = typeof b === 'string' ? parseFloat(b.replace(/,/g, '')) : b;

                return valueA - valueB;
            },
        },
        {
            name: 'Budget',
            selector: (row) => <b>{AddCommasToAmount(row.budget)}</b>,
            sortable: true,
            width: "150px",
            wrap: true,
            center: true,
            sortFunction: (a, b) => {
                a = a.budget;
                b = b.budget;
                const valueA = typeof a === 'string' ? parseFloat(a.replace(/,/g, '')) : a;
                const valueB = typeof b === 'string' ? parseFloat(b.replace(/,/g, '')) : b;

                return valueA - valueB;
            },
        },
        {
            name: 'Actions (L2)',
            selector: (row) => row.l2_status == 0 ? "Pending" : row.l2_status == 1 ? "Approved" : "Rejected",
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Actions (L3)',
            selector: (row) => row.l3_status == 0 ? "Pending" : row.l3_status == 1 ? "Approved" : "Rejected",
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Action',
            width: "200px",
            selector: (row) => {
                return (
                    <>
                        <div className='user_action'>
                            <span className='pr-4'><Link to={`/view-budget/${row.id}`}><AiOutlineEye /></Link></span>
                            {
                                show_l1_action_btn(authData.user.user_type, row.l2_status, row.l3_status) ?
                                    <span className='pr-4'><Link
                                        to={`/edit-budget/${row.id}`}><FaEdit /></Link></span> : ""
                            }
                            {
                                show_l1_action_btn(authData.user.user_type, row.l2_status, row.l3_status) ?
                                    <span className='pr-4 text-danger'
                                        onClick={() => delete_budget(row.id)}><MdDelete></MdDelete></span> : ""
                            }
                        </div>
                    </>
                );
            },
        }
    ];
    const delete_budget = async (budget_id) => {
        if (budget_id) {
            const result = window.confirm("Are you sure you want to delete the budget?");
            if (result) {
                try {
                    let formData = {
                        "budget_id": budget_id
                    }
                    // console.log(data)
                    // const updated_data=data.splice(0,1);

                    //console.log(updated_data);
                    // return false;
                    const budget_response = await delete_budget_by_id(formData);
                    if (budget_response.data.status) {
                        let index = data.findIndex((u) => u.id === budget_id);


                        if (index >= 0) {
                            const updated_data = data.splice(index, 1);
                            fetchBudgets();
                        }
                        toast.success(budget_response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.error(budget_response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                } catch (error) {
                    toast.error("getting error while delete user.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } else {
            toast.error("budget id required.", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const handleChangePage = (page) => {
        // console.log('page', page)
        setCurrentPage(page);
    };

    // const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    //  console.log('currentPagecurrentPage', currentPage)
    const NestedTable = ({ subhead }) => {
        const columns = [
            {
                name: 'L2 Approval',
                selector: row => row.code,
                width: '150px',
            },
            {
                name: 'L3 Approval',
                selector: row => row.name,
                width: '200px',
            },
            {
                name: 'Budget OrderRef No',
                selector: row => row.provisional_budget,
                width: '200px',
            },
            {
                name: 'Budget Order Date',
                selector: row => row.balance,
                width: '150px',
            },
        ];
        return (
            <div style={{ paddingLeft: '50px' }}> {/* Adjust padding as needed */}
                <DataTable
                    columns={columns}
                    data={subhead}
                    noHeader
                    pagination={false}
                />
            </div>
        );
    };
    const ExpandableComponent = ({ data }) => <NestedTable subhead={data.subhead} />;
    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
    // return (
    //     <div>
    //         <DataTable
    //             customStyles={customStyles}
    //             className="dataTables_wrapper"
    //             columns={columns}
    //             data={data}
    //             fixedHeader
    //             fixedHeaderScrollHeight="600px"
    //             pagination
    //             paginationPerPage={rowsPerPage}
    //             paginationTotalRows={data.length}
    //             paginationRowsPerPageOptions={[10, 25, 50, 100]}
    //             onChangeRowsPerPage={handleChangeRowsPerPage}
    //             expandableRows
    //             expandableRowsComponent={ExpandableComponent}
    //         />
    //     </div>
    // )
}
export default BudgetPaymentList
