import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router-dom";
import { getSchemeBankAccountList } from '../../../helper/Api';
import { BiSolidEdit } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const SchemeAccountList = (filterDate) => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let { state } = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const [vendor, setVendorData] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Department Name',
            selector: (row) => row.department_name,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Department Code',
            selector: (row) => row.department_code,
            sortable: true,
            width: "180px",
            right: false
        },
        {
            name: 'Department Level',
            selector: (row) => row.department_level,
            sortable: true,
            width: "180px",
            right: false
        },
        {
            name: 'Scheme Name',
            selector: (row) => row.scheme_name,
            sortable: true,
            wrap: true,
            width: "300px",
            right: false
        },
        {
            name: 'Scheme No',
            selector: (row) => row.scheme_code,
            sortable: true,
            width: "180px",
            right: false
        },
        {
            name: 'Account Name',
            selector: (row) => row.account_name,
            sortable: true,
            wrap: true,
            width: "180px",
            right: false
        },
        {
            name: 'Account No',
            selector: (row) => row.account_no,
            sortable: true,
            width: "180px",
            right: false
        },
        // {
        //     name: 'L2 Approval',
        //     selector: (row) => row.l2_approval == null ?
        //         authData.user.user_type == 'l2' ? <button type="button" className='btn btn-secondary'>Pending</button> : 'Pending'
        //         : row.l2_approval == 1 ? 'Approved' : 'Rejected',
        //     sortable: true,
        //     width: "150px",
        //     right: false
        // },
        // {
        //     name: 'L3 Approval',
        //     selector: (row) => row.l3_approval == null ?
        //         authData.user.user_type == 'l3' ? <button type="button" className='btn btn-secondary'>Pending</button> : 'Pending'
        //         : row.l3_approval == 1 ? 'Approved' : 'Rejected',
        //     sortable: true,
        //     width: "150px",
        //     right: false
        // },
        // {
        //     name: 'Actions',
        //     width: "150px",
        //     selector: (row) => {
        //         return <>
        //             <span style={{ marginRight: '25px' }}><Link to={`/viewchildSchemeAccount/${row.id}`}><AiOutlineEye /></Link></span>
        //             {
        //                 (authData.user.user_type === 'l1') ? <Link to={`/editchildSchemeAccount/${row.id}`}><BiSolidEdit /></Link> : ''
        //             }
        //         </>
        //     },
        //     center: true,
        // },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handleFilterClick = async () => {
        // await fetchBeneficiary(schemeId)
        setData([]);
        await fetchList()
    };
    const clearFilter = async () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        await fetchList()
        // await fetchBeneficiary(schemeId)
    };

    const fetchList = async () => {
        try {
            const resp = await getSchemeBankAccountList();
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    // console.log('fdate', filterDate.fromDate);
    useEffect(() => {
        fetchList();
    }, [filterDate.searchStr])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default SchemeAccountList
