import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    get_beneficiary,
    addDepository,
    addDepartment
} from '../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ADD_CHALLAN_FAIL, ADD_CHALLAN_START } from "../../RTK/Slices/ChallanSlice";
import { toast } from 'react-toastify';


const AddDepository = () => {
    const [beneficiaries, setBeneficiaries] = useState([]);
    const { authData } = useSelector((state) => state.authData);
    const { id } = useParams();
    const [formData, setFormData] = useState({
        id: '',
        beneficiary_id: '',
        depository_type: '',
        description: '',
        valid_from: '',
        valid_to: '',
        approval_date: '',
        attachments: [],
        created_by: authData && authData.status ? authData.user.id : null
    });
    const depositoryTypes = ['Letter of Intent', 'Work Order/Purchase order', 'Tender Document', 'EMD/Bid Security', 'Performance Security', 'AMC', 'Other'];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchLists = async () => {
        const result = await get_beneficiary();
        if (result.data.status) {
            setBeneficiaries(result.data.list)
        }
    }

    function validateFile(input) {
        console.log('input', input);
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 5 MB

        const file = input.files[0];
        const fileSize = file.size;
        const fileType = file.type;

        if (!allowedTypes.includes(fileType)) {
            // document.getElementById('error').innerText = "Invalid file type. Please upload a PDF or DOCX file.";
            toast.error('File Type Not Allowed', {
                position: toast.POSITION.TOP_CENTER
            });
            input.value = ''; // Clear the input
            return false;
        }

        if (fileSize > maxSize) {
            toast.error('File size greater than 5MB', {
                position: toast.POSITION.TOP_CENTER
            });
            // document.getElementById('error').innerText = "File size exceeds the limit of 5 MB.";
            input.value = ''; // Clear the input
            return false;
        }

        // If validation passed, you can do something with the file
        // console.log("File is valid:", file);
    }

    const handleAttachmentChange = (event) => {
        if (validateFile(event.target)) {
            const files = event.target.files;
            setFormData({
                ...formData,
                attachment: files
            });
        };
    };

    const handleChallanFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const previewHandler = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        fetchLists();
    }, [])

    const handleSubmit = async (e) => {
        if (!id) {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault();
            }
        }
        try {
            const post_data = new FormData();
            for (let i = 0; i < formData.attachments.length; i++) {
                post_data.append('attachment[]', formData.attachments[i]);
            }
            post_data.append('beneficiary_id', formData.beneficiary_id);
            post_data.append('depository_type', formData.depository_type);
            post_data.append('approval_date', formData.approval_date);
            post_data.append('valid_from', formData.valid_from);
            post_data.append('valid_to', formData.valid_to);
            post_data.append('description', formData.description);
            const response = await addDepository(post_data);
            let addDepositoryResponseData = response.data;
            if (addDepositoryResponseData.status) {
                navigate('/depository');
            } else {
                toast.error(addDepositoryResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_CHALLAN_FAIL(addDepositoryResponseData.message));
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating Depository'));
        }
    }

    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4> Add Depository</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="beneficiary_id" className="col-sm-4 col-form-label">Select Beneficiary <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control "
                                                            id="beneficiary_id" name='beneficiary_id'
                                                            required
                                                            // disabled={type === 'view'}
                                                            value={formData.beneficiary_id}
                                                            onChange={handleChallanFormData}
                                                        >
                                                            <option>Select Beneficiary</option>
                                                            {
                                                                beneficiaries.map((st) => {
                                                                    return (
                                                                        <option value={st.id}
                                                                            key={st.id}>{st.company_name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="scheme_id" className="col-sm-4 col-form-label">Select Type <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" name='depository_type'
                                                            required
                                                            // disabled={type === 'view'}
                                                            value={formData.depository_type}
                                                            onChange={handleChallanFormData}
                                                        >
                                                            <option>Select depository type</option>
                                                            {
                                                                depositoryTypes.map((st) => {
                                                                    return (
                                                                        <option value={st}
                                                                            key={st}>{st}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Description
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <textarea className="form-control"
                                                            name='description'
                                                            required
                                                            onChange={handleChallanFormData}
                                                            //    value={formData.description}
                                                            placeholder="Enter description">{formData.description}</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="inputScheme" className="col-sm-4 col-form-label">Approval Date
                                                        :</label>
                                                    <div className="col-sm-8">
                                                        <input type="date" className="form-control"
                                                            name='approval_date'
                                                            //    required
                                                            value={formData.approval_date}
                                                            onChange={handleChallanFormData} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="inputScheme" className="col-sm-4 col-form-label">Valid From
                                                        :</label>
                                                    <div className="col-sm-3">
                                                        <input type="date" className="form-control"
                                                            name='valid_from'
                                                            //    required
                                                            value={formData.valid_from}
                                                            onChange={handleChallanFormData} />
                                                    </div>
                                                    <div className="col-sm-1 text-center">to</div>
                                                    <div className="col-sm-3">
                                                        <input type="date" className="form-control"
                                                            name='valid_to'
                                                            //    required
                                                            value={formData.valid_to}
                                                            onChange={handleChallanFormData} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-group">
                                                            <label className="input-group-btn my-0 mt-2 mr-2">
                                                                Upload Document  <span className="text-danger">*</span>
                                                            </label>
                                                            {
                                                                !id && !formData?.attachments?.length ? (
                                                                    <label className='p-1'>
                                                                        <input id="" type="file"
                                                                            className='form-control'
                                                                            onChange={handleAttachmentChange}
                                                                            name='attachment'
                                                                            accept=".pdf,.jpeg,.jpg,.png"
                                                                            required
                                                                            multiple />
                                                                    </label>
                                                                ) : (
                                                                    formData.attachments.map((att, i) => {
                                                                        return (
                                                                            <a className="mt-2 mx-2" href={att.file_url}
                                                                                target='_blank'>Attachment {i + 1}</a>);
                                                                    })
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2 text-center">
                                                <Button variant="primary" onClick={handleSubmit}> SUBMIT </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )

};

export default AddDepository;