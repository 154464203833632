import React from 'react';
import Tools from "../../../components/Tools/Tools"
import AddActivity from '../../../components/Multi/Department-Activity/AddActivity';

const AddActivityPage = () => {
  return (
    <div>
      <Tools/>
      <AddActivity />
    </div>
  )
}

export default AddActivityPage
