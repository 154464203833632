import React, { useEffect, useState, useRef } from 'react'
import Tools from '../../components/Tools/Tools'
import BudgetAnalysis from '../../components/BudgetAnalysis/BudgetAnalysis'
import DashboardBox from '../../components/DashboardBox/DashboardBox'
import DashboardBarGraph from '../../components/DashboardBarGraph/DashboardBarGraph'
import { get_financial_year, get_dashboard_data } from '../../helper/Api';
import moment from 'moment';

const Dashboard = () => {
const prevDataRef = useRef({
  "account_name": "",
  "bank_name": "",
  "branch_name": "",
  "ifsc_code": "",
  "account_type": "",
  "account_no":"",
  "account_balance":"",
  "balance_date":"",
  "interest":"",
  "interest_date":"",
  'added_by':"",
});
const reloadList = useRef(false)
const [bankAccount, setBankAccount] = useState(false)
const [data,setData]=useState({
  total_provisional_budget: {
    provisional_budget: 0,
    released_budget: 0
  },
  currenyFyUtilization: 0,
  prevFyUtilization:0,
  prevYearBalance: 0,
  payable_expanses: 0,
  sanctioned_amount: 0,
  accumulated: [
    {
      deduction: 0
    }
  ],
  reimbursement_details: [
    {
      under_approval: 0
    },
    {
      payment_approval: 0
    }
  ]
});

const handleFinancialYearChange = async (e) => {
  const val = e.target.value;
  const {data} = await get_dashboard_data(val);
  if(data.status){
    setData(data);
  }
}


const fetch_dashboard_data=async()=>{
  try {
    const fy = await get_financial_year();
  if (fy.data.status) {
    const fy_id = fy.data.list.find(e => e.start_year === moment().format('YYYY'));
    if (fy_id) {
      const {data}=await get_dashboard_data(fy_id.id);
      if(data.status){
        setData(data);
      }else{
        setData({})
      }
    }
  }
  } catch (error) {
    setData({})
  }
}

const handleEditAccount = (data) => {
  prevDataRef.current = data;
  setBankAccount(true);
}

const changePopUpState = (cState, reload = false) => {
  setBankAccount(cState);
  if(reload) {
    reloadList.current = true;
  }
}

useEffect(() => {
  fetch_dashboard_data();
}, [])

  return (
    <div>
      <Tools/>
      <BudgetAnalysis handleYearDropdown={handleFinancialYearChange} isShowPopup={bankAccount} previewBankAccountData={prevDataRef.current} handlePopUpState={changePopUpState}/>
      <DashboardBox data={data} showPopupFun={handleEditAccount} listReload={reloadList.current}/>
      <DashboardBarGraph/>
    </div>
  )
}

export default Dashboard
