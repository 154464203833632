import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    success: false,
    department:[],
    departmentDetail:{}
};

export const DepartmentSlice=createSlice({
    name:"departments",
    initialState,
    reducers:{
        GET_DEPARTMENT_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        GET_DEPARTMENT_SUCCESS:(state,action)=>{
            state.loading = false;
            state.error = null;
            state.success = true;
            state.department=action.payload;
        },        
        GET_DEPARTMENT_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
        ADD_DEPARTMENT_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
            // console.log(state);
        },
        ADD_DEPARTMENT_SUCCESS:(state,action)=>{
            state.loading = false;
            state.error = null;
            state.success = true;
            const old_users=state.department;
            let new_departments=[...old_users,action.payload];
            state.department = new_departments;
        },        
        ADD_DEPARTMENT_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
            console.log(action.payload);
        }, 
        UPDATE_DEPARTMENT_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
            // console.log(state);
        },
        UPDATE_DEPARTMENT_SUCCESS:(state,action)=>{
            state.loading = false;
            state.error = null;
            state.success = true;
            let index = state.department.findIndex((u) => u.id === action.payload.id);
            if(index>=0){
                state.department[index] = action.payload;
            }
        },        
        UPDATE_DEPARTMENT_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
            console.log(action.payload);
        },
        DEPARTMENT_DETAILS:(state,action)=>
        {
            state.loading = false;
            state.error = null;
            state.success = true;
            state.departmentDetail=action.payload;
        }   
    }
})

export const {
    ADD_DEPARTMENT_FAIL,
    ADD_DEPARTMENT_START,
    ADD_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAIL,
    GET_DEPARTMENT_START,
    GET_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAIL,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_START,
    DEPARTMENT_DETAILS
}=DepartmentSlice.actions;
export default DepartmentSlice.reducer;