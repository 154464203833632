import React from 'react';
import Tools from "../../components/Tools/Tools";
import EditBeneficiaryReimbursement from '../../components/BeneficiaryReimbursement/EditBeneficiaryReimbursement';

export default function EditBeneficiaryReimbursementPage() {
  return (
    <div>
      <Tools />
      <EditBeneficiaryReimbursement />
    </div>
  )
}