import React, {useState} from 'react'
import Tools from '../../components/Tools/Tools';
import PPAListComponent from '../../components/Ppa/PpaList';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader'


export default function Ppa() {
    // const {authData} = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('approval_date');
    const [searchStr, setToSearchStr] = useState('');

    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
    };
    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn} type="PPA" />
            <div className='scheme p-2'>
                <PPAListComponent fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr}/>
            </div>
        </div>
    )
}