import React from 'react'
import Tools from "../../../components/Tools/Tools"
import AddDepartmentForm from '../../../components/Multi/Multi-Department/AddDepartment'

const AddDepartment = () => {
  return (
    <div>
      <Tools></Tools>
      <AddDepartmentForm></AddDepartmentForm>
    </div>
  )
}

export default AddDepartment