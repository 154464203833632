import React from 'react';
import './DashboardBox.css'
import { AddCommasToAmount } from '../../helper/Utils'
import InterestFromBankBox from './InterestFromBankBox'
import { Col, Container, Row } from 'react-bootstrap'


const DashboardBox = ({ data, showPopupFun, listReload }) => {
  return (
    <>
      <div className="add_new_user">
        <Container fluid>
          <Row>
            <Col sm={4}>
              <div className='dashboardStatCard'>
                <h3>Scheme Budget</h3>
                <h6>Provisioned Budget</h6>
                <h5>{AddCommasToAmount(data.total_provisional_budget.provisional_budget)}</h5>
                <h6>Released Budget</h6>
                <h5>{AddCommasToAmount(Number(data.total_provisional_budget.released_budget).toFixed(2))}</h5>
                <h6>Prev. Year Balance</h6>
                <h5>{AddCommasToAmount(Number(data.prevYearBalance).toFixed(2))}</h5>
                <hr />
                <h4>Total Budget</h4>
                <h4>{AddCommasToAmount((Number(data.prevYearBalance) + Number(data.total_provisional_budget.released_budget)).toFixed(2))}</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className='dashboardStatCard' style={
                { backgroundColor: '#FFE7E5' }
              }>
                <h3>Utilized Budget</h3>
                <h6>From Current FY</h6>
                <h5>{AddCommasToAmount(Number(data.currenyFyUtilization).toFixed(2))}</h5>
                <h6> From Prev. Year Balance</h6>
                <h5>{AddCommasToAmount(Number(data.prevFyUtilization).toFixed(2))}</h5>
                <hr />
                <h4>FY 2024-25 Utilized</h4>
                <h4>{AddCommasToAmount(Number(data.currenyFyUtilization + data.prevFyUtilization).toFixed(2))}</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className='dashboardStatCard'>
                <h3>Scheme Balance</h3>
                <h6>From Current FY</h6>
                <h5>{AddCommasToAmount(Number(data.total_provisional_budget.released_budget - data.currenyFyUtilization).toFixed(2))}</h5>
                <h6>From Prev. Year Balance</h6>
                <h5>{AddCommasToAmount(Number(data.prevYearBalance - data.prevFyUtilization).toFixed(2))}</h5>
                <hr />
                <h4>Total Balance</h4>
                <h4>{AddCommasToAmount(((Number(data.prevYearBalance) + Number(data.total_provisional_budget.released_budget)-Number(data.currenyFyUtilization + data.prevFyUtilization))).toFixed(2))}</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className='dashboardStatCard' style={
                { backgroundColor: '#FFE7E5' }
              }>
                <h3>Approvals</h3>
                <h6><span>{data.pending_invoices_count}</span> Invoices for approval</h6>
                <h5>{AddCommasToAmount(data.sanctioned_amount)}</h5>
                <h6>{data.payable_expenses_invoice_count} Invoices for Payment</h6>
                <h5>{AddCommasToAmount(data.payable_expanses)}</h5>
                <h6>0 Reimbursment for approval</h6>
                <h4>{data?.reimbursement_details[0]?.under_approval > 0 ? data?.reimbursement_details[0]?.under_approval : 0}</h4>
                <h6>0 Reimbursment for payment</h6>
                <h4>{data?.reimbursement_details[1]?.payment_approval > 0 ? data?.reimbursement_details[1]?.payment_approval : 0}</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className='dashboardStatCard'>
                <h3>Utilized Budget</h3>
                <p>Current Month</p>
                <h6>From Current FY</h6>
                <h5>{AddCommasToAmount(Number(data.currentMonthUtilize_cfy).toFixed(2))}</h5>
                <h6>From Prev. Year Balance</h6>
                <h5>{AddCommasToAmount(Number(data.currentMonthUtilize_pfy).toFixed(2))}</h5>
                <hr />
                <h4>Current Month Utilized</h4>
                <h4>{AddCommasToAmount(Number(data.currentMonthUtilize_cfy + data.currentMonthUtilize_pfy).toFixed(2))}</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className='dashboardStatCard' style={
                { backgroundColor: '#FFE7E5' }
              }>
                <h3>Accumulated Deduction</h3>
                <h5>{AddCommasToAmount(data?.accumulated || 0)}</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="dashboardBox">
        <InterestFromBankBox handleEditAccount={showPopupFun} reloadList={listReload} />
      </div>
    </>
  )
}

export default DashboardBox
